@use "@angular/material" as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$concent-primary: mat.define-palette(mat.$indigo-palette);
$concent-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$concent-warn: mat.define-palette(mat.$red-palette);

$concent-theme: mat.define-light-theme(
    (
        color: (
            primary: $concent-primary,
            accent: $concent-accent,
            warn: $concent-warn
        )
    )
);

@include mat.all-legacy-component-themes($concent-theme);

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
